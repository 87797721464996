

header {
    background-color: #3498db;
    color: #fff;
    padding: 15px;
    text-align: center;
}
.section1{
    display: block;
}

/* body {
   
    display: flex;
    flex-direction: column;
   
    
} */




.main-div1 {
    display: flex;
    /* flex-wrap: wrap; */
    /* justify-content: space-around; */
    margin-bottom: 20px;
}

.container1 {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    /* flex: 0 0 calc(33.33% - 20px); */
    /* max-width: calc(33.33% - 20px); */
    width: 33%;
    text-align: center;
}

.container1 h3 {
    margin-bottom: 10px;
}

.container1 h2 {
    color: blue;
    margin-top: 10px;
}

.course-list, .grades, .messages {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    margin: 10px;
    flex: 1 1 100%;
}

.course-list table, .grades table {
    width: 100%;
    border-collapse: collapse;
}

.course-list th, .grades th, .course-list td, .grades td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.course-list th, .grades th {
    background-color: #f2f2f2;
}

.course-list a, .grades a {
    color: blue;
}

.messages ul {
    list-style-type: none;
}

.messages ul li {
    margin-bottom: 10px;
}

.messages ul li a {
    color: blue;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .container, .course-list, .grades, .messages {
        flex: 1 1 100%;
        max-width: 100%;
    }
}
