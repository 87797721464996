

header {
    background-color: #3498db;
    color: #fff;
    padding: 30px;
    text-align: center;
}


section.assign-course-to-student {
    /*background-color: #fff; */
    border-radius: 5px;
    padding: 20px;
    display: block;
    translate: 0 -25px;
    /* width: 80%; */
    /* margin: 20px auto; */
     /* max-width: 800px; */
    
}


.assign-course-form,
.remove-assigned-course-form {
    margin-bottom: 20px;
}

form {
    width: 100%;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
}

select,
input[type="submit"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

input[type="submit"] {
    background-color: blue;
    color: #fff;
    cursor: pointer;
}

input[type="submit"]:hover {
    background-color: #555;
}
.assign-course-form1 {
    translate: 100px 0;
    width: 70%;
}

.remove-assigned-course-form1{
    width: 70%;
    translate: 100px 0;
}

