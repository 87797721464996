/* Reset CSS */

header {
    background-color: #3498db;
    color: #fff;
    padding: 30px;
    text-align: center;
}



section.manage-permissions {
    /* background-color: #fff; */
    border-radius: 5px;
    padding: 20px;
    margin: 20px auto;
    translate: 0 -30px;
    width: 70%;
    display: block;
}

h2 {
    margin-bottom: 20px;
}

h3 {
    margin-bottom: 10px;
}

.permission-list table,
.add-permission form {
    /* width: 100%;
    margin-bottom: 20px; */
}

table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

th {
    background-color: #f2f2f2;
}

.add-permission .form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="text"],
select,
input[type="submit"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

input[type="submit"] {
    background-color: blue;
    color: #fff;
    cursor: pointer;
}

input[type="submit"]:hover {
    background-color: #555;
}
