

header {
    background-color: #3498db;
    color: #fff;
    padding: 20px;
    text-align: center;
}


/* section{
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.65); 
    backdrop-filter: blur(4px);
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
} */


h1 {
    text-align: center;
}

.student {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 20px;
    margin-top: 20px;
    border-radius: 30px;
    box-shadow: rgba(0.5, 0.5, 0.5, 0.5);
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 5px;
}
/* .container{
    max-width: 400px;
    background-color: #fff; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    border-radius: 10px; 
    padding: 20px;
    text-align: center;
    margin-left: 30%;
    margin-right: 50px;
    margin-bottom: 15%;
} */

/* section{
    background-color: rgba(255, 255, 255, 0.65); 
    backdrop-filter: blur(4px);
    
} */

