
.container {
    align-items: center; /* Vertical alignment */
    justify-content: center; /* Horizontal alignment */
    /* height: 110vh; Full height of viewport */
    margin-bottom: 60px;
}

.course {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 20px;
    margin: 10px;
    background-color: #fff; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}
.btn-assessment,
.btn-assignment {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.btn-assessment:hover,
.btn-assignment:hover {
    background-color: #45a049; /* Darker Green */
}

header {
    background-color: #3498db;
    color: #fff;
    padding: 20px;
    text-align: center;
}

/* section {
    padding: 100px;
    background-color: rgba(255, 255, 255, 0.65); 
    backdrop-filter: blur(4px); 
    display: flex;
    align-items: center; 
    justify-content: center; Horizontal alignment
    height: 100vh;
    position: static;
} */