

header {
    background-color: rgba(40, 40, 156, 0.63);
    color: white;
    padding: 1em;
    text-align: center;
}

.login-section {
    flex-direction: column;
    padding: 2em;
    padding-top: 0px !important;
    margin-top:  0px !important;
    flex: 1;
    display: flex;
    
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
    
}
.form-div{
    background-color: rgba(255, 255, 255, 0.733); 
    backdrop-filter: blur(4px); 
    padding: 4%;
    border-radius: 20px;
    font-size: 29px;
    margin-top: 25px;
}

form {
    font-size: 25px;
    text-align: center; 
    
}

label {
    margin-bottom: 8px;
    display: block;
}

input {
    width: 100%;
    height: 120%;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
}

.login-button {
    padding: 10px 20px;
    color: #fff;
    background-color: blue;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.login-button:hover {
    background-color: #2980b9;
}



label{
    text-align: left;
}
