

header {
    background-color: #3498db;
    color: #fff;
    padding: 20px;
    text-align: center;
}



section {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.65); 
    backdrop-filter: blur(4px); 
    
}

.dashboard-container {
    width: 80%;
    margin: 0 auto;
    background-color: #fff; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    border-radius: 10px; 
    padding: 20px;
    translate: 0 0;
}

.quick-links a {
    display: block;
    background-color: #3498db;
    color: #fff;
    text-decoration: none;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px; /* Add rounded corners to buttons */
    transition: background-color 0.3s ease-in-out;
}

.quick-links a:hover {
    background-color: #297fb8; /* Change background color on hover */
}

.course-status,
.completed-courses {
    margin-top: 20px;
}

.course-status h3,
.completed-courses h3 {
    color: #3498db;
}

.course-status ul,
.completed-courses ul {
    list-style: none;
    padding: 0;
}

.course-status li,
.completed-courses li {
    margin: 10px 0;
}

footer {
    background-color: rgba(40, 40, 156, 0.63);
    color: white;
    padding: 1em;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
}
