/* Reset CSS */
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */





section.assign-course {
    /* background-color: #fff; */
    border-radius: 5px;
    padding: 80px; 
    /* margin: 200%; */
    translate: 0 -40px;
    margin: 20px auto;
    display: block;
    height: 100vh;
    /* max-width: 600px; */
    font-size: 30px;
}

/* h2 {
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
} */

label {
    display: block;
    margin-bottom: 5px;
}

select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

input[type="submit"] {
    background-color: blue;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

input[type="submit"]:hover {
    background-color: #555;
}

/* Responsive Styles */
@media (max-width: 768px) {
    section.assign-course {
        padding: 10px;
    }
}
