/* Reset CSS */


header {
    background-color: #3498db;
    color: #fff;
    padding: 30px;
    text-align: center;
}


/*h1 {
    color: #333;
    margin-bottom: 5px;
}*/

/*h4 {
    color: #666;
}*/


section.report {
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
}

h2 {
    color: #333;
    margin-bottom: 10px;
}

.report-options {
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    margin-bottom: 5px;
}

input[type="date"],
select,
input[type="submit"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

input[type="submit"] {
    background-color: blue;
    color: #fff;
    cursor: pointer;
}

input[type="submit"]:hover {
    background-color: #555;
}

.generated-report {
    border-top: 1px solid #ccc;
    padding-top: 20px;
}

.report{
    display: block;
    translate: 0 -30px;
    padding: 20px;
    font-size: 25px;
}
