body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    /* min-height: 100vh; */
    overflow: auto; 
    animation: backgroundScroll 20s linear infinite; 
}

@keyframes backgroundScroll {
    0% {
        background-image: url('../../assets/images/uni1.jpg'); 
    }
    25% {
        background-image: url('../../assets/images/uni2.jpg'); 
    }
    50% {
        background-image: url('../../assets/images/uni3.jpg'); 
    }
    75% {
        background-image: url('../../assets/images/uni4.jpg'); 
    }
    100% {
        background-image: url('../../assets/images/uni1.jpg'); 
    }
}



nav {
    background-color: white;
    padding: 1em;
    font-size: 23px;
    text-align: center;
}

nav a {
    color: black;
    text-decoration: none;
    padding: 1em;
    margin: 0 1em;
    transition: background-color 0.3s ease, color 0.3s ease;
    
}

section {
    padding: 2em;
    padding-top: 0px !important;
    margin-top:  0px !important;
    flex: 1;
    /* height: 100vh; */
    display: flex;
    font-size: 23px;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.733); 
    backdrop-filter: blur(1px); 
}


nav a:hover {
    background-color: rgba(40, 40, 156, 0.63);
    color: #fff;
}
/* Add this new style for button container */
.button-container {
    margin-top: 20px;
}

/* Add styles for Login button */
.login-button {
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    margin-right: 10px;
}

/* Add styles for Signup button */
.signup-button {
    padding: 10px 20px;
    background-color: #2ecc71;
    color: #fff;
    border: none;
    font-size: 20px;
    border-radius: 5px;
    cursor: pointer;
}
.Detail_div{
    margin: 0;
    width: 50%;
    height: 70%;
}
