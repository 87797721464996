

header {
    background-color: #3498db;
    color: #fff;
    padding: 30px;
    text-align: center;
}





section.performance {
    /* background-color: #fff; */
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
}

h2 {
    color: #333;
    margin-bottom: 10px;
}

.performance-metrics {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.metric {
    flex-basis: 30%;
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 5px;
    text-align: center;
}

.metric h3 {
    color: #333;
    margin-bottom: 10px;
}

.metric p {
    font-size: 20px;
    font-weight: bold;
}

.detailed-performance {
    border-top: 1px solid #ccc;
    padding-top: 20px;
}
.performance {
    /* background-color: #fff; */
    border-radius: 5px;
    padding: 20px;
    display: block;
    margin-bottom: 20px;
    margin: 60px;
}

.performance-metrics {
    display: flex;
    justify-content: space-between;
}

.metric {
    flex-basis: 30%;
    /* background-color: #f9f9f9; */
    padding: 15px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 20px;
}

.metric h3 {
    color: #333;
    margin-bottom: 10px;
}

.metric p {
    font-size: 20px;
    font-weight: bold;
}

.student-selection{
    display: block;
    width: 50%;
    text-align: center;
    translate: 70px 0;
}
