nav {
    background-color: white;
    padding: 1em;
    font-size: 23px;
    text-align: center;
}

nav a {
    color: black;
    text-decoration: none;
    padding: 1em;
    margin: 0 1em;
    transition: background-color 0.3s ease, color 0.3s ease;
    
}

nav a:hover {
    background-color: rgba(40, 40, 156, 0.63);
    color: #fff;
}