

header {
    background-color: #3498db;
    color: #fff;
    padding: 30px;
    text-align: center;
}



section.received-messages {
    /* background-color: #fff; */
    border-radius: 5px;
    display: block;
    padding: 20px;
    height: 100vh;
    width: 70%;
    margin: 20px auto;
    translate: 0 -30px;
    /* max-width: 800px; */
}

h2 {
    margin-bottom: 20px;
}

.message-list {
    margin-top: 20px;
    text-align: center;
}

.message {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;
    display: flex;
}

.message-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.sender-name {
    font-weight: bold;
}

.message-date {
    color: #888;
}

.message-content p {
    margin-bottom: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    section.received-messages {
        padding: 10px;
    }
}
