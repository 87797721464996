body {
    font-family: Arial, sans-serif;
    /* margin: 0;
    padding: 0;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column; */
   
    overflow: auto; 
}


.signup-section {
    padding: 2em;
    /* flex: 1;
    display: flex;
    flex-direction: column; */
    align-items: center;
    /* background-color: rgba(255, 255, 255, 0.7); */
}

form {
    text-align: center;
    /* max-width: 400px; */
    margin: 0 auto;
}

label {
    display: block;
    margin: 10px 0;
    font-weight: bold;
}

input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    box-sizing: border-box;
}

.signup-button {
    padding: 10px 20px;
    color: #fff;
    background-color: #2ecc71;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.signup-button:hover {
    background-color: #27ae60;
}

footer {
    background-color: rgba(40, 40, 156, 0.63);
    color: white;
    padding: 1em;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
}

.form-div{
    background-color: rgba(255, 255, 255, 0.733); 
    backdrop-filter: blur(4px); 
    padding: 4%;
    border-radius: 20px;
    margin-top: 25px;
    width: 50%;
}

/* .form-container{
    display: block;
    justify-content: space-between;
    max-width: 500px; 
    margin: auto; 
} */

.form-container label,
    .form-container input {
        /* display: block; */
        margin-bottom: 10px;
    }

    .form-container label {
        /* width: 45%;  */
        text-align: left;
        padding-right: 10px;
    }

    .form-container input textarea{
        width: 100%; 
        border: none; 
        background-color: transparent;
        border-top: hidden; 
        border-bottom: 1px solid blue;
        outline: none; 
        padding: 5px; 
    }
    textarea{
        width: 100%;
    }
