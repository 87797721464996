body {
   
    display: flex;
    flex-direction: column;
   
    
}

header {
    background-color: #3498db;
    color: #fff;
    padding: 20px;
    text-align: center;
}

/* nav {
    background-color: white;
    padding: 1em;
    text-align: center;
}

nav a {
    color: black;
    text-decoration: none;
    padding: 1em;
    margin: 0 1em;
    transition: background-color 0.3s ease, color 0.3s ease;
    
}

nav a:hover {
    background-color: rgba(40, 40, 156, 0.63);
    color: #fff; 
}*/

.section1{
    display: block;
}

.admin-section {
   margin-bottom: 30px;
    
}
/* .main-div{
     
    background-color: rgba(255, 255, 255, 0.65); 
} */
/* section{
    background-color: rgba(255, 255, 255, 0.65);
    backdrop-filter: blur(4px); 
} */

.statistic {
   width: 25%;
   background-color: chocolate;

}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

th, td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
}

th {
    /* background-color: #555; */
    color: black;
}

ul {
    list-style-type: none;
    padding: 0;
}

footer {
    background-color: rgba(40, 40, 156, 0.63);
    color: white;
    padding: 1em;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
}
.container1{
    width: 22%;
    height: 10%;
    border: 1px solid black;
    /* margin-left: 10 px; */
    padding-left: 15px !important;
    border-radius: 20px;
    margin-top: 20px;
    /* display: flex; */
    background-color:  rgba(255, 255, 255, 0.733);
    backdrop-filter: blur(4px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    backdrop-filter: blur(4px); 
}
.main-div {
    display: flex;
    /* width: 150%; */
    justify-content: space-around; /* Adjust as needed */
}
.student-list{
    margin: 35px;
    display: block;
    width: 200vh;
}

/* .container {
    text-align: center;
    margin: 20px;
} */ 

/* .container h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: blue;
}

.container i {
    margin-right: 20px;
    font-size: 60px;
} */
