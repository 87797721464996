

@import url(
"https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");



@media screen and (max-width: 600px) {
  .nav-right a {display: none;}
  .nav-right a.icon {
    float: right;
    display: block;
  }
}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 600px) {
  .nav-right.responsive {position: relative;}
  .nav-right.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .nav-right.responsive a {
    float: none;
    display: block;
    text-align: left;
    position: relative;
    right: 50px;
  }
}



/* responsive Side nav*/
@import url(
"https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@media screen and (max-width: 600px) {
  .sidenav a:not(first child) {display: none;}
  .sidenav a.icon {
    display: block;
  }
}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 600px) {
  .sidenav.responsive {position: relative; overflow: auto;}
  .sidenav.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;

  }
 
}

/* body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background: linear-gradient(to bottom right, #3498db, #2ecc71);
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: auto; 
    animation: backgroundScroll 20s linear infinite;
    background-color: rgba(255, 255, 255, 0.733); 
} */

header {
    background-color: #3498db;
    color: #fff;
    padding: 20px;
    text-align: center;
}


.button1{
  	margin: 10px;
  	padding: 10px;
  	border-color: transparent;
  	background-color: lightblue;
  	color: black;
  	border-radius: 10px;
    margin-left: 70px;
  	font-size: 15px;
  	font-weight: bold;
  }
 .button1:hover{
 	transform: scale(1.04);
 }


 nav {
    background-color: white;
    padding: 1em;
    text-align: center;
}

nav a {
    color: black;
    text-decoration: none;
    padding: 1em;
    margin: 0 1em;
    transition: background-color 0.3s ease, color 0.3s ease;
    
}

nav a:hover {
    background-color: rgba(40, 40, 156, 0.63);
    color: #fff;
}

footer {
    background-color: rgba(40, 40, 156, 0.63);
    color: white;
    padding: 1em;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
}





.student-profile{
    width: 90%;
    background-color: white !important; 
    backdrop-filter: blur(4px); 
    border-radius: 30px;
    margin-top: 0px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); 
    backdrop-filter: blur(4px); 
    padding: 40px;
    margin-left: 60px;
    background-color: rgba(255, 255, 255, 0.65); 
    backdrop-filter: blur(4px);
}






.container-fluid{
	margin-top: 150px;
}

.dropdown{
	margin-left: 16%;
	font-size: 24px;
  max-width: 200px;
}


/* Chat starts here */
/* Button used to open the chat form - fixed at the bottom of the page */
  .open-button {
    background-color: rgba(40, 40, 156, 0.63);
  color : white;
  padding : 14px 20px;
  border : none;
  cursor : pointer;
  opacity : 0.8;
  position : fixed;
  bottom : 100px;
  right : 28px;
  width : 100px;
  border-radius : 50px;
  }/* The popup chat - hidden by default */
  .chat-popup {
  display : none;
  position : fixed;
  bottom : 0;
  right : 15px;
  border : #f1f1f1 solid 0;
  z-index : 9;
  }
  /* form {
  max-width : 600px;
  margin : 20px auto;
  padding : 20px;
  border : #ccc solid 1px;
  border-radius : 30px;
  box-shadow : 0 0 10px rgb(0, 0, 0, 0.1);
  } */
/* Add styles to the form container */
  /* .form-container {
  max-width : 300px;
  padding : 10px;
  background-color : white;
  } */
/* Full-width textarea */
  /* .form-container textarea {
  width : 100%;
  padding : 15px;
  margin : 5px 0 22px 0;
  border : none;
  background : #f1f1f1;
  resize : none;
  min-height : 200px;
  } */
/* When the textarea gets focus, do something */
  /* .form-container textarea:focus {
  background-color : #ddd;
  outline : none;
  } */
 /* Set a style for the submit/send button */
  .form-container .btn {
background-color: #3498db;
  color : white;
  padding : 16px 20px;
  border : none;
  cursor : pointer;
  width : 40%;
  margin-bottom : 10px;
  opacity : 0.8;
  border-radius: 20px;
  margin-left: 10px;
  }

  /* .form-container .btn2 {
      background-color: #2ecc71;
      color : white;
      padding : 16px 20px;
      border : none;
      cursor : pointer;
      width : 40%;
      margin-bottom : 10px;
      opacity : 0.8;
      border-radius: 20px;
      margin-left: 10px;
      } */

      .btn2 {
        background-color: #2ecc71;
        color : white;
        padding : 16px 20px;
        border : none;
        cursor : pointer;
        width : 40%;
        margin-bottom : 10px;
        opacity : 0.8;
        border-radius: 20px;
        margin-left: 10px;
        }
/* Add a red background color to the cancel button */
  /* .form-container .cancel {
    background-color: #3498db;
  } */
/* Add some hover effects to buttons */
  .form-container .btn:hover, .open-button:hover {
  opacity : 1;

  }
  
  @media screen and (max-width: 768px) {
  .open-button {
  margin-left : 20px;
  }


 }
 /* Chat ends here */

/* -------------Student-Profile css-----------------------*/
.dashboard {
/* margin-top: 10%; */
/* padding-top: 70px; */
/* margin-left: 18%; */
/* background-color: none; */
padding: 30px;
background-color: rgba(255, 255, 255, 0.733); 
/* width: 100%; */
translate: 0 2%;
}

.student-profile .card {
  border-radius: 10px;
}

.student-profile .card .card-header .profile_img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin: 10px auto;
  /* margin-top: 15%; */
  border: 10px solid #ccc;
  border-radius: 50%;
}

.student-profile .card h3 {
  font-size: 20px;
  font-weight: 700;
}

.student-profile .card p {
  font-size: 16px;
  color: #000;
}

.student-profile .table th,
.student-profile .table td {
  font-size: 14px;
  /* padding: 5px 10px; */
  color: #000;
  padding: 10px;
  text-align: left;
  border: 1px solid black;
  background-color: transparent; /* Set the background color to black */
  color: black;
}

/*----------------FeedBack ------------------------------*/
form{
  color: black;
  width: 70%;
  /* align: center; */
  opacity: 1.7;
  margin-left: 15%;
  position: relative;
  padding: 16px 32px;
  background-color: #cecece;
}
  

input{
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
input, select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical 
}
input[type=submit] {
  background-color: #0A9DDD;
  color: white;
  /* padding: 12px 20px; */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}




/* ----------Edit User ----------*/
.open-button1 {
    background-color: rgba(40, 40, 156, 0.63);
    color: white;
    padding: 20px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    position: fixed;
    top: 100px;
    right: 50px;
    border-radius: 5px;
  }
  
  /* The popup chat - hidden by default */
  .edit-popup {
    display: none;
    position: fixed;
    top: 100px;
    right: 15px;
    border: 3px none;
    background-color: none;
    z-index: 99;
  }
  
  /* Add styles to the form container */
  .form-container1 {
    max-width: 400px;
    padding: 10px;
    background-color: white;
    border: 3px solid #f1f1f1;
    overflow-y: auto; /* Add this line to make it vertically scrollable */
    max-height: 400px; /* Set a maximum height to enable scrolling */
}
  
  /* Full-width textarea */
  .form-container1 input {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
    resize: none;
  }
  
  /* When the textarea gets focus, do something */
  .form-container1 input:focus {
    background-color: #ddd;
    outline: none;
  }
  
  /* Set a style for the submit/send button */
  .form-container1 .btn {
    background-color: #4e6783;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 40%;
    margin-bottom:10px;
    opacity: 0.8;
    border-radius: 20px;
    margin-left: 10px;
  }
  
  /* Add a red background color to the cancel button */
  .form-container1 .cancel {
    background-color: rgb(24, 22, 22);
  }
  
  /* Add some hover effects to buttons */
  .form-container1 .btn:hover, .open-button1:hover {
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    .open-button1 {
        margin-left: 20px;
    }
  }


  /* ---------------ASSIGNMENTS------------------*/

  .dashboard {
/* margin: 5%; */
/* background-color: none; */
background-color: rgba(255, 255, 255, 0.466) !important; 
backdrop-filter: blur(4px);
/* padding: 1rem; */
}

#customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  #customers td, #customers th {
    border: 1px solid black;
    /* padding: 8px; */
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  #customers tr:hover {background-color: #ddd;}
  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #2c3e50;
    color: white;
  }

  

  .submit-button {
    background-color: #4e6783;
    color: white;
    border: none;
    cursor: pointer;
    position: fixed;
    border-radius: 5px;
    /* margin-top: 10px; */
  }

  .submit-popup {
    display: none;
    position: fixed;
    bottom: 40px;
    right: 70px;
    border: none;
    z-index: 9;
    width: 50%;
  }
  
  /* Add styles to the form container */
  .file-container {
    padding: 10px;
    background-color: white;
    border: 3px solid #f1f1f1;
  }
  
  /* Full-width textarea */
  .file-container input {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
    resize: none;
    min-height: 200px;
  }
  
  /* When the textarea gets focus, do something */
  .file-container input:focus {
    background-color: #ddd;
    outline: none;
  }
  
  /* Set a style for the submit/send button */
  .file-container .btn {
    background-color: #4e6783;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 40%;
    margin-bottom:10px;
    opacity: 0.8;
    border-radius: 20px;
    margin-left: 10px;
  }
  
  /* Add a red background color to the cancel button */
  .file-container .cancel {
    background-color: rgb(24, 22, 22);
  }
  
  /* Add some hover effects to buttons */
  .file-container .btn:hover, .submit-button:hover {
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    .submit-button {
        margin-left: 20px;
    }
  }

/* -----------------Grades---------------*/
.report-button {
    background-color: #4e6783;
    color: white;
    border: none;
    cursor: pointer;
    position: fixed;
    border-radius: 5px;
    margin-top: 20px;
    height: 40px;
  }
  .report-popup{
    display: none;
    position: fixed;
    bottom: 100px;
    left: 26%;
    border: none;
    z-index: 9;
  }
  .btn {
    background-color: #4e6783;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    width: 40%;
    border-radius: 20px;
    margin-left: 10px;
  }
