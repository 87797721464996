/* Reset CSS */
/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
} */



header {
    background-color: #3498db;
    color: #fff;
    padding: 30px;
    text-align: center;
}



section.assign-grade {
    /* background-color: #fff; */
    border-radius: 5px;
    /* padding: 20px; */
    /* margin: 20px auto; */
    display: block;
    translate: 0 -33px;
    height: 100vh;
    /* max-width: 600px; */
}

h2 {
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
    height: 50%;
}

label {
    display: block;
    margin-bottom: 5px;
}

select, input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

input[type="submit"] {
    background-color: blue;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 20px;
    font-size: 20px;
}

input[type="submit"]:hover {
    background-color: #555;
}

/* Responsive Styles */
@media (max-width: 768px) {
    section.assign-grade {
        padding: 10px;
    }
}

.assignButton{
    width: 80%;
    height: 40px;
    background-color: blue;
    color: white;
    border-radius: 20px;
}
